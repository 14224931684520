<template>
  <div class="z-org-pick" :class="{'z-org-pick__radio': !multiple, 'not-dept': notPickDept}">
    <a-space align="start" class="z-org-pick__space" :size="0">
      <a-space direction="vertical">
        <a-space>
          <a-input-search
            v-model="searchValue"
            enter-button
            placeholder="请输入您要搜索的名称"
            @search="handleInputEnter"
          />
          <a-button
            type="primary"
            @click="handleResetTree"
          >
            重置
          </a-button>
        </a-space>
        <a-spin :spinning="treeLoading">
          <a-tree
            ref="Tree"
            v-model="checked"
            :tree-data="treeData"
            :check-strictly="!multiple"
            :expanded-keys.sync="expandedKeys"
            :selectable="false"
            blockNode
            checkable
            show-icon
            @check="handleCheck"
          >
            <a-icon slot="dept" type="folder" style="color: #4b95f3" theme="filled" />
            <a-icon slot="personnel" type="user" />
            <template slot="title" slot-scope="{ title }">
              <span v-if="isSearch && title.indexOf(searchValue) > -1">
                {{ title.substr(0, title.indexOf(searchValue)) }}
                <span style="color: #f50">{{ searchValue }}</span>
                {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
              </span>
              <span v-else>{{ title }}</span>
            </template>
          </a-tree>
        </a-spin>
      </a-space>
      <a-space direction="vertical" class="z-org-pick__value--wrapper">
        <p class="z-org-pick__value">已选择的部门或成员</p>
        <a-list class="z-org-pick__value--list" size="small" :data-source="checkedUser">
          <a-list-item slot="renderItem" slot-scope="item">
            <a-space>
              <a-icon slot="personnel" type="user" />
              {{ item.title }}
            </a-space>
            <a-button
              size="small"
              slot="actions"
              type="text"
              icon="close"
              shape="circle"
              @click.stop="handleRemoveNode(item)"
            />
          </a-list-item>
        </a-list>
      </a-space>
    </a-space>
  </div>
</template>

<script>
import _https from "@/api/workOrder";
import { flattenDeep, isEmpty, isEqual, omit, uniq, remove, pull, cloneDeep, isArray } from "lodash-es"

let dataList = []

/**
 * @description 将树形数据标准化
 */
function handleTreeData(__DATA__ = [], __TYPE__ = 'dept', chain = []) {
  return __DATA__?.map(item => {
    const {employees, children, depId, userid, depName, employeeName, depParentId} = item || {}
    const obj = {
      disabled: false,
      selectable: false,
      key: null,
      class: `z-org-pick--${__TYPE__}`,
      title: null,
      node: omit(item, ['children', 'employees']), // 无子级及人员的数据，备用
      full: item, // 完整的数据结构，备用
      __TYPE__, // 区分数据类型
      slots: {icon: __TYPE__},
      scopedSlots: {title: 'title'},
      __ParentID__: '', // 父级 ID
      __ParentID_CHAIN__: chain, // 树形链
    }

    let child = [];

    if (isEqual(__TYPE__, 'dept')) {
      obj.key = depId
      obj.title = depName
      obj.__ParentID__ = depParentId
    }

    if (isEqual(__TYPE__, 'personnel')) {
      obj.key = userid;
      obj.title = employeeName
      obj.__ParentID__ = depId
    }

    // 将一级链一直往下递归获取到完整的树形父级id链
    obj.__ParentID_CHAIN__ = [...obj.__ParentID_CHAIN__, obj.__ParentID__]

    if (!isEmpty(employees)) {
      const personnel = handleTreeData(employees, 'personnel', obj.__ParentID_CHAIN__)
      child = [...child, ...personnel];
    }

    if (!isEmpty(children)) {
      const department = handleTreeData(children, 'dept', obj.__ParentID_CHAIN__)
      child = [...child, ...department];
    }

    dataList.push(obj)

    return {...obj, children: child};
  })
}

function getEchoList(ids = []) {
  return dataList.filter(v => ids.includes(v.key))
}

export default {
  name: "TreeSelect",
  data() {
    return {
      treeLoading: false,
      treeData: [],
      expandedKeys: [],
      searchValue: "",
      isSearch: false,
      checked: [],
      checkedUser: [],
      checkedDept: [],
    }
  },
  props: {
    value: Array,
    multiple: {type: Boolean, default: true}, // 是否多选
    notPickDept: {type: Boolean, default: false}, // 禁止选部门 未实现
  },
  mounted() {
  },
  created() {
    this.getList();
  },
  watch: {
    checked: {
      handler() {
        this.handleChange();
      },
      deep: true,
    },
    value: {
      handler() {
        this.handleEcho()
      },
    },
  },
  methods: {
    // 获取节点数据
    getList() {
      this.treeLoading = true
      _https.getTreeData()
        .then((res) => {
          this.treeLoading = false

          if (res.code == 200) {
            dataList = []
            const tree = res.data
            this.treeData = handleTreeData(isEmpty(tree) ? [] : [tree], 'dept');
            this.expandedKeys = this.treeData?.[0].key && [this.treeData?.[0].key] || []
            // 防止回显数据未请求成功
            if (!isEmpty(this.value)) this.handleEcho();
          } else {
            this.$message.error(res.message || '获取数据失败');
          }
        }).catch(() => {
          this.treeLoading = false
        })
    },
    // 处理搜索
    handleInputEnter() {
      this.isSearch = !isEmpty(this.searchValue)
      this.expandedKeys = uniq(flattenDeep(dataList?.filter(item => item.title.indexOf(this.searchValue) > -1)?.map(v => v.__ParentID_CHAIN__) || []));

      if (!this.isSearch) this.handleResetTree()

      if (this.isSearch && isEmpty(this.expandedKeys)) {
        this.$message.error("未匹配到数据");
      }
    },
    // 处理重置树节点
    handleResetTree() {
      this.isSearch = false;
      this.searchValue = "";
      this.expandedKeys = this.treeData?.[0].key && [this.treeData?.[0].key] || []
    },
    // 处理数据勾选
    handleCheck(checkedKeys, {checkedNodes, node}) {
      if (this.multiple) {
        let list = checkedNodes?.map(node => node?.data?.props?.dataRef)
        this.checkedUser = remove(list, (node) => isEqual(node.__TYPE__, "personnel"))
        this.checkedDept = list;
      } else {
        const item = node?._props?.dataRef || {};
        if (isEqual(item.__TYPE__, 'personnel')) {
          this.checkedUser = [item];
          this.checkedDept = [];
          this.checked = [item.key]
        } else {
          this.checkedUser = [];
          this.checkedDept = [];
          this.checked = [];
        }
      }
    },
    // 处理删除选中的值
    handleRemoveNode(node) {
      this.checkedUser = remove(this.checkedUser, (item) => !isEqual(item.key, node.key))
      // 需要将父级选中一并移除，否则会出现无法移除问题。
      this.checked = pull(this.checked, node.key, ...node.__ParentID_CHAIN__)
      this.handleChange();
    },
    // 根据 ID 获取人员信息
    handlePersonById(ids) {
      if (isEmpty(ids)) return []
      return (isArray(ids) ? ids : [ids]).map(id => dataList.find(v => isEqual(v.key, id)));
    },
    // 处理批量删除人员
    handleBatchRemove(ids) {
      if (isEmpty(ids)) return []
      const list = this.handlePersonById(ids);
      const removeList = uniq(flattenDeep(list.map(v => [v.key, v.__ParentID_CHAIN__]))) || [];
      this.$set(this, 'checkedUser', remove(this.checkedUser, (item) => !removeList.includes(item.key)));
      this.$set(this, 'checked', pull(this.checked, ...removeList));
      this.handleChange();
    },
    // 处理数据回显
    handleEcho() {
      this.$nextTick(() => {
        this.checkedUser = getEchoList(this.value);
        this.expandedKeys = uniq(flattenDeep(this.checkedUser.map(v => v.__ParentID_CHAIN__) || []))
        this.checked = cloneDeep(this.value);
      })
    },
    // 处理数据改变
    handleChange() {
      const {checked, checkedUser, checkedDept} = this;
      // checked：所有选中的id; checkedUser: 选中的用户 checkedDept 选中的部门
      this.$emit('change', {checked, checkedUser, checkedDept})
    },
  },
  computed: {},
}
</script>

<style lang="scss">
.z-org-pick {

  &__space {
    width: 100%;

    > .ant-space-item {
      flex: 1;
      height: 500px;
      overflow-y: auto;

      &:first-child {
        border-right: 1px solid #bcbcbc;
      }

      &:last-child {
        border-left: 1px solid #bcbcbc;
        margin-left: -1px;
        flex: 1;
      }
    }
  }

  &__value {
    line-height: 32px;
    width: 100%;

    &--wrapper {
      padding: 0 20px;
      width: 100%;
    }

    &--list {
      padding: 0 20px;
    }
  }

  &__radio {
    .ant-tree {
      .z-org-pick--dept {
        .ant-tree-checkbox {
          display: none !important;
        }

        .ant-tree-node-content-wrapper {
          padding-left: 0;
        }
      }

      .z-org-pick--personnel {
        .ant-tree-checkbox {
          display: inline-block !important;
        }
      }
    }
  }

  .ant-spin-nested-loading {
    min-height: 100px;
  }
}
</style>
