<template>
  <div>
    <a-row>
      <a-col :span="6"></a-col>
      <a-col :span="18" class="flex-end">
        <span>当前数据统计范围：
          {{ dataInfo.statisticsStartTime || "-" }} -
          {{ dataInfo.statisticsEndTime || "-" }}
        </span>
        <span style="padding: 0 15px">更新：{{ dataInfo.dataUpdateTime || "-" }}</span>
        <a-button @click="goToKPISetPage" type="link" style="font-size: 16px;color: red">
          <a-icon type="setting" style="color: #8b8e92"/>
          KPI考核设置
        </a-button>
      </a-col>
    </a-row>

    <a-row style="padding: 20px 0">
      <a-col :span="16">
        <a-dropdown v-model="weekVisible" :trigger="['click']" @click="weekVisible = true">
          <span class="ant-dropdown-link">
            按周{{ getWeekType }}
            <a-icon type="down"/>
          </span>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-radio-group
                  v-model="searchForm.type"
                  style="margin-bottom: 20px"
              >
                <a-radio :value="1">按周</a-radio>
                <a-radio disabled :value="2">按周期</a-radio>
              </a-radio-group>
              <div>
                <a-select
                    v-model="searchForm.assessmentCycleId"
                    style="width: 240px;"
                    placeholder="请选择"
                    @click.stop="(e) => e.preventDefault()"
                    @change="changeAssessmentCycleId"
                >
                  <a-select-option
                      v-for="item in weekList"
                      :key="item.assessmentCycleId"
                      :value="item.assessmentCycleId"
                  >
                    {{ item.startDate }}~{{ item.endDate }}
                  </a-select-option>
                </a-select>
              </div>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-divider type="vertical" style="margin: 0 15px"/>
        <a-dropdown>
          <span class="ant-dropdown-link">
           {{ searchForm.traineeGroupName || "所属分组" }}
            <a-icon type="down"/>
          </span>
          <template #overlay>
            <a-menu style="width: 200px;min-height: 50px">
              <a-menu-item
                  v-for="(a) of traineeGroupList"
                  :key="a.traineeGroupId"
                  @click="clickTraineeGroupId"
              >
                {{ a.traineeGroupName }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <a-divider type="vertical" style="margin: 0 15px"/>
        <a-dropdown :trigger="['click']" v-model="traineeNameVisible" @click="traineeNameVisible = true">
          <span class="ant-dropdown-link">
           {{ searchForm.traineeName || "学员名称" }}
            <a-icon type="down"/>
          </span>
          <template #overlay>
            <a-menu style="width: 240px;padding: 12px">
              <a-select
                  show-search
                  v-model="searchForm.traineeName"
                  :placeholder="'请输入'"
                  show-arrow
                  :filter-option="false"
                  :loading="select_loading"
                  @search="searchTrainee"
                  :default-active-first-option="false"
                  style="width: 200px"
                  @click.stop="(e) => e.preventDefault()"
              >
                <a-select-option
                    v-for="item in traineeList"
                    :key="item.traineeName"
                    :value="item.traineeName"
                >
                  {{ item.traineeName }}
                </a-select-option>
              </a-select>
            </a-menu>
          </template>
        </a-dropdown>
        <a-divider type="vertical" style="margin: 0 15px"/>
        <a-dropdown>
          <span class="ant-dropdown-link">
           {{ searchForm.platformName || "所属平台" }}
            <a-icon type="down"/>
          </span>
          <template #overlay>
            <a-menu style="width: 200px">
              <a-menu-item
                  v-for="(item) of platformList"
                  :key="item.key"
                  @click="changePlatform"
              >{{ item.label }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-col>
      <a-col :span="8" class="flex-end">
        <!--<a-button-->
        <!--    class="mr-20"-->
        <!--    type="primary"-->
        <!--    icon="search"-->
        <!--    @click="bindSearch"-->
        <!--&gt;-->
        <!--  查询-->
        <!--</a-button>-->
        <a-button
            class="mr-20"
            icon="redo"
            @click="resetSearch"
        >
          重置
        </a-button>
        <a-button
            class="mr-20"
            type="primary"
            :loading="export_loading"
            @click="handleExport(1)"
        >
          导出KPI结果明细
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import _API from "@/api/projectManagement";
import debounce from "lodash/debounce";
import { createNamespacedHelpers } from "vuex";
import { downloadExcel } from "@/utils";
import { isEqual } from "lodash-es";
import dayjs, { Dayjs } from "dayjs";

const {mapGetters} = createNamespacedHelpers("temporary_parameters");
export default {
  name: "KPI",
  props: {
    id: {
      type: [String, Number],
    },
    kpiBusinessId: {
      type: [String, Number],
    },
    traineeGroupId: {
      type: [String, Number],
    },
  },
  data() {
    this.searchTrainee = debounce(this.searchTrainee, 800);
    return {
      loading: false,
      export_loading: false,
      searchForm: {
        type: 1,
        traineeGroupId: this.$route.query.traineeGroupId || '',
        traineeGroupName: this.$route.query.traineeGroupName || "",
        assessmentCycleId: "",
        businessId: "",
        platform: "PLATFORM_TOTAL",
        platformName: "平台合计",
        traineeName: "",
      },
      weekList: [],
      traineeList: [],
      traineeGroupList: [],
      select_loading: false,
      dataInfo: {},
      platformList: [
        {
          key: "PLATFORM_TOTAL",
          label: "平台合计",
        },
        {
          key: "ALL",
          label: "全部平台",
        },
        {
          key: "DOUYIN",
          label: "抖音",
        },
        {
          key: "KUAISHOU",
          label: "快手",
        },
        {
          key: "OTHER",
          label: "其他",
        },
      ],
      weekVisible: false,
      traineeNameVisible: false,
      dataArray: {},
    };
  },
  mounted() {
    this.getWeekOptions();
    this.getTraineeGroupOptions();
    // this.getTraineeOptionsNew();
  },
  methods: {
    getKpiUpdateTime() {
      let params = {
        businessId: this.kpiBusinessId || this.getTemporaryParameters.kpiBusinessId,
        assessmentCycleId: this.searchForm.assessmentCycleId,
      };
      _API.getKpiUpdateTime(params).then(res => {
        if(res.code == 200) {
          this.dataInfo = res.data || {};
        } else {
          this.dataInfo = {};
          this.$message.error(res.message || "获取数据失败");
        }
      });
    },
    getWeekOptions() {
      let params = {
        businessId: this.kpiBusinessId || this.getTemporaryParameters.kpiBusinessId,
      };
      _API.getWeekOptions(params).then(res => {
        if(res.code == 200) {
          this.weekList = res.data || [];
          const {assessmentCycleId} = (res.data || []).find(v => v.isSelected) || {};
          this.$set(this.searchForm, "assessmentCycleId", assessmentCycleId || this.weekList[0]?.assessmentCycleId);
          this.dataArray = (res.data || []).find(v => v.isSelected) || {};
          this.getKpiUpdateTime();
        } else {
          this.weekList = [];
          this.$message.error(res.message || "获取周数据失败");
        }
      });
    },
    getTraineeGroupOptions() {
      let params = {
        businessId: this.getTemporaryParameters.kpiBusinessId || this.kpiBusinessId,
      };
      _API.getTraineeGroupOptions(params).then(res => {
        if(res.code == 200) {
          this.traineeGroupList = res.data || [];
        } else {
          this.traineeGroupList = [];
          this.$message.error(res.message || "获取分组数据失败");
        }
      });
    },
    getTraineeOptionsNew(traineeName) {
      this.select_loading = true;
      let params = {
        businessId: this.kpiBusinessId || this.getTemporaryParameters.kpiBusinessId,
        traineeName: traineeName,
      };
      _API.getTraineeOptionsNew(params).then(res => {
        if(res.code == 200) {
          this.traineeList = res.data || [];
          this.select_loading = false;
        } else {
          this.select_loading = false;
          this.traineeList = [];
          this.$message.error(res.message || "获取分组数据失败");
        }
      });
    },
    resetSearch() {
      const {assessmentCycleId} = (this.weekList || []).find(v => v.isSelected) || {};
      this.searchForm = {
        type: 1,
        traineeGroupId: "",
        traineeGroupName: "",
        assessmentCycleId: assessmentCycleId || this.weekList[0].assessmentCycleId,
        businessId: "",
        platform: "PLATFORM_TOTAL",
        platformName: "平台合计",
        traineeName: "",
      };
      this.getKpiUpdateTime();
    },
    handleExport(type) {
      let API = "";
      let params = {
        businessId: this.kpiBusinessId || this.getTemporaryParameters.kpiBusinessId,
        platform: this.searchForm.platform,
        assessmentCycleId: this.searchForm.assessmentCycleId,
        traineeGroupId: this.searchForm.traineeGroupId,
        traineeName: this.searchForm.traineeName,
      };
      switch(type) {
        case 1:
          // console.log("导出周KPI结果明细数据");
          API = _API.exportWeekDetail(params);
          this.exportList(this.export_loading, API, `学员KPI结果_按周_${ this.dataArray.startDate }～${ this.dataArray.endDate }`);
          break;
      }
    },
    async exportList(loading, API, name) {
      loading = true;
      try {
        const res = await API;
        downloadExcel(res, name);
      } catch(e) {
        // console.log(e);
        loading = false;
        this.$message.error("导出失败");
      }

    },
    goToKPISetPage() {
      let route = this.$router.resolve({
        path: "/KPIAssessmentSetting",
        query: {
          projectId: this.id,
          kpiBusinessId: this.getTemporaryParameters.kpiBusinessId,
        },
      });
      window.open(route.href, "_blank");
    },
    searchTrainee(keyword) {
      keyword = keyword && keyword.trim();
      if(keyword) {
        this.getTraineeOptionsNew(keyword);
      }
    },
    changeAssessmentCycleId() {
      this.getKpiUpdateTime();
      this.dataArray = this.weekList?.find(v => isEqual(this.searchForm?.assessmentCycleId, v.assessmentCycleId)) || {};
    },
    clickTraineeGroupId(visible) {
      const {key} = visible;
      let traineeGroupName = this.traineeGroupList.filter(v => v.traineeGroupId == key)[0].traineeGroupName;
      this.$set(this.searchForm, "traineeGroupId", key);
      this.$set(this.searchForm, "traineeGroupName", traineeGroupName);
    },
    changePlatform(visible) {
      const {key} = visible;
      let platformName = this.platformList.filter(v => v.key == key)[0].label;
      this.$set(this.searchForm, "platform", key);
      this.$set(this.searchForm, "platformName", platformName);
    },
  },
  computed: {
    ...mapGetters(["getTemporaryParameters"]),

    getWeekType() {
      const id = this.searchForm?.assessmentCycleId;
      const item = this.weekList?.find(v => isEqual(id, v.assessmentCycleId)) || {};

      // return item?.isSelected &&
      // dayjs().isAfter(dayjs(item.startDate), "d") &&
      // dayjs().isBefore(dayjs(item.endDate)) ? "（本周）" : "";
      return dayjs().startOf('week').add(1, 'day') <= dayjs(item.startDate)&&
      dayjs().endOf('week').add(1, 'day') >= dayjs(item.endDate) ? "（本周）" : "";
    },
  },
};
</script>

<style scoped lang="less">

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //margin: 20px 0;
}

.mr-20 {
  margin-right: 20px;
}

.ant-dropdown-link {
  cursor: pointer;
}
</style>
