<template>
  <div>
    <a-card>
      <a-row>
        <a-col :span="18">
          <h1 class="project-name">{{ project_data.projectName || "-" }}
            <a-icon v-if="project_data.isCreatorOrOwner" type="info-circle" theme="filled" style="cursor: pointer"
                    @click="showProjectModalVisible"/>
          </h1>
        </a-col>
        <a-col :span="6" v-if="isShow">
          <span>项目更新时间：{{ project_data.atime || "-" }}</span>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="6">
          <div class="time-s">
            {{ project_data.startDate || "-" }} - {{ project_data.endDate || "-" }}
            <a-tag
                :color="project_data.projectStatus == 1 ? 'green' : project_data.projectStatus == 2 ? '#1890ff' :project_data.projectStatus == 3 ? 'red' : '-'"
                style="font-size: 14px;margin-left: 8px"
            >{{
                project_data.projectStatus == 1 ? "进行中" : project_data.projectStatus == 2 ? "未开始" : project_data.projectStatus == 3 ? "已结束" : "-"
              }}
            </a-tag>
          </div>
          <div><span>品牌：{{ project_data.principalName || "-" }}</span>
            <span style="padding-left: 20px">负责人：{{ project_data.ownerName || "-" }}</span></div>
          <div>是否对客：{{ project_data.dataUsageScope ?  "是" : '否' }}</div>
        </a-col>
        <a-col :span="12" v-if="isShow">
          <div class="num-box">
            <div class="num-text">
              <div class="a-tag-text">
                <router-link
                    :to="{
                  name: 'projectDetail',
                  query: { tabActive: 3,id:id, kpiBusinessId:project_data.kpiBusinessId }
                }"
                    target="_blank"
                    class="detail-item__value"
                >
                  {{ project_data.traineeCount || "0" }}
                </router-link>
              </div>
              <div>学员</div>
            </div>
            <div class="num-text">
              <div>{{ project_data.groupCount || "0" }}</div>
              <div>分组</div>
            </div>
            <!-- <div class="num-text">
              <div class="a-tag-text">
                <router-link
                    :to="{
                  name: 'projectDetail',
                  query: { tabActive: 3,id:id,radioValue:'0',kpiBusinessId:project_data.kpiBusinessId }
                }"
                    target="_blank"
                    class="detail-item__value"
                >
                  {{ project_data.byFollowerCount || "0" }}
                </router-link>
              </div>
              <div>跟进中学员</div>
            </div>
            <div class="num-text">
              <div>{{ project_data.awemeGroupCount || "0" }}</div>
              <div>短视频分组</div>
            </div>
            <div class="num-text">
              <div>{{ project_data.liveGroupCount || "0" }}</div>
              <div>直播分组</div>
            </div>
            <div class="num-text">
              <div class="a-tag-text">
                <router-link
                    :to="{
                  name: 'projectDetail',
                  query: { tabActive: 2,id:id,kpiBusinessId:project_data.kpiBusinessId }
                }"
                    target="_blank"
                    class="detail-item__value"
                >
                  {{ project_data.countFollower || "0" }}
                </router-link>
              </div>
              <div>跟进人员</div>
            </div> -->
          </div>
        </a-col>
      </a-row>
    </a-card>

    <!--编辑项目-->
    <projectModal
        ref="projectModal"
        :visible="projectModal_visible"
        title="编辑项目"
        :principal-list="principalList"
        :id="id"
        :project_data="project_data"
        @handleCancel="handleCancel"
    />
  </div>
</template>

<script>
import _API from "@/api/projectManagement";
import baseApi from "@/api/base";
import projectModal from "../components/projectModal";
import { createNamespacedHelpers } from "vuex";

const {mapActions} = createNamespacedHelpers("temporary_parameters");

export default {
  name: "detail_header",
  props: {
    id: {
      type: [String, Number],
    },
    isShow: {
      type: Boolean,
      default: true,
    },
  },
  components: {projectModal},
  data() {
    return {
      project_data: {},
      projectModal_visible: false,
      principalList: []
    };
  },
  mounted() {
    this.fetchPrincipalList();
    this.getProjectDetail();
  },
  methods: {
    ...mapActions(["setTemporaryParameters"]),
    // 获取主体列表
    async fetchPrincipalList() {
      try {
        const {code, data, message} = await baseApi.fetchPrincipalList();

        if(code === 200) {
          this.principalList = [
            ...data.map(item => {
              return {label: item.principal_name, value: item.id};
            })
          ];
        } else {
          this.$message.error(message);
          this.principalList = [];
        }
      } catch(e) {
        console.error(e);
        this.principalList = [];
      }
    },
    getProjectDetail() {
      _API.getProjectDetail(this.id).then((res) => {
        if(res.code == 200) {
          this.project_data = res.data || {};
          this.setTemporaryParameters(res.data);
        } else {
          this.$message.error(res.message || "获取详情失败");
        }
      });
    },
    showProjectModalVisible() {
      this.projectModal_visible = true;
      this.$refs.projectModal.getProjectDisplay();
    },
    handleCancel() {
      this.projectModal_visible = false;
      this.getProjectDetail();
    },
  }
};
</script>

<style scoped lang="less">
h1 {
  font-size: 24px;
}

.time-s {
  padding-bottom: 10px;
}

.num-box {
  display: flex;
  justify-content: center;
  align-items: center;

  .num-text {
    text-align: center;
    padding: 0 24px;

    :nth-child(1) {
      font-size: 20px;
      font-weight: bold;
    }

    .a-tag-text {
      color: #40a9ff;
      cursor: pointer;
    }
  }
}
</style>
