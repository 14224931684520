<template>
  <div>
    <a-form-model
        ref="searchForm"
        :model="searchForm"
        layout="inline"
    >
      <a-row>
        <a-col :span="6">
          <a-form-model-item label="分组类型">
            <a-select
                v-model="searchForm.traineeGroupType"
                style="width: 160px;"
            >
              <a-select-option
                  key=""
              >
                全部
              </a-select-option>
              <a-select-option
                  v-for="item in statusList"
                  :value="item.id"
                  :key="item.id"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <!-- <a-col :span="6">
          <a-form-model-item label="跟进人员">
            <a-input
                v-model.trim="searchForm.followerName"
                allow-clear
                placeholder="请输入"
                style="width: 160px;"
            />
          </a-form-model-item>
        </a-col> -->
        <a-col class="flex-end">
          <a-button
              class="mr-20"
              type="primary"
              icon="search"
              @click="bindSearch"
          >
            查询
          </a-button>
          <a-button
              class="mr-20"
              icon="redo"
              @click="resetSearch"
          >
            重置
          </a-button>
          <a-button
              class="mr-20"
              type="primary"
              icon="plus"
              @click="createGroup"
              :disabled="btnStatus"
          >
            添加分组
          </a-button>
        </a-col>
      </a-row>
      <a-row v-if="list.length">
        <div style="font-size: 14px">
          学员(经销商){{ project_data.traineeCount || 0 }}
          <!-- 播主(主营帐号){{ project_data.authorCount || 0 }}，
          短视频分组{{ project_data.awemeGroupCount || 0 }}，
          短视频学员{{ project_data.awemeTraineeCount || 0 }}，
          直播分组{{ project_data.liveGroupCount || 0 }}，
          直播学员{{ project_data.liveTraineeCount || 0 }} -->
        </div>
      </a-row>
    </a-form-model>

    <div class="data-table">
      <a-table
          :loading="loading"
          :columns="columns"
          :data-source="list"
          :row-key="(record, index) => index"
          :pagination="false"
          :scroll="{ x: 1000}"
      >
        <div slot="groupInfo" slot-scope="text, record">
          <div class="detail-item">
            <span class="detail-item__label">名称：</span>
            <router-link
                :to="{
                  name: 'dealerGroupDetail',
                  params: { groupId: record.traineeGroupId }
                }"
                target="_blank"
                class="detail-item__value"
            >
              {{ record.traineeGroupName || "-" }}
            </router-link>
          </div>
          <div class="detail-item">
            <span class="detail-item__label">类型：</span>
            <span class="detail-item__value">{{ record.traineeGroupType == 1 ? "短视频" : record.traineeGroupType == 2 ? "直播" : record.traineeGroupType == 3 ? '短视频+直播' : '-' }}</span>
          </div>
        </div>
        <div slot="statistics" slot-scope="text, record">
          <div class="detail-item">
            <span class="detail-item__label">学员（经销商）：</span>
            <span class="detail-item__value">{{ record.traineeCount || 0 }}</span>
          </div>
          <!-- <div class="detail-item">
            <span class="detail-item__label">播主（主营账号）：</span>
            <a-button
                type="link"
                v-if="!record.authoreCount"
                :loading="record.loading"
                @click="getItemNum(record,0)">获取
            </a-button>
            <span class="detail-item__value" v-else>{{ record.authoreCount }}</span>
          </div> -->
        </div>
        <div slot="oauth_status" slot-scope="text, record">
          <div class="detail-item">
            <a-button
                type="link"
                v-if="!record.p1FinishCount"
                :loading="record.authore_loading"
                @click="getItemNum(record,1)">获取
            </a-button>
            <div v-if="record.linkAuthor == 1">
              <div>
                <span class="detail-item__label">P1：</span>
                <router-link
                    :to="{
                  name: 'authorList',
                  query: { authorGroupId: record.traineeGroupId, authorGroupName: record.traineeGroupName, groupCate: 1,isGroupCate:'2' }
                }"
                    target="_blank"
                    class="detail-item__value"
                >
                  已授权{{ record.p1FinishCount }}，
                  未授权{{ record.p1NotYetCount }}，
                  授权过期{{ record.p1ExpiredCount }}
                </router-link>
              </div>
              <div>
                <span class="detail-item__label">P2：</span>
                <router-link
                    class="detail-item__value"
                    :to="{
                  name: 'authorList',
                  query: { authorGroupId: record.traineeGroupId, authorGroupName: record.traineeGroupName,groupCate: 1,isGroupCate:'2' }
                }"
                    target="_blank"
                >
                  已授权{{ record.p2FinishCount }}，
                  未授权{{ record.p2NotYetCount }}，
                  授权过期{{ record.p2ExpiredCount }}
                </router-link>
              </div>
            </div>
          </div>
          <span class="detail-item__value" v-if="record.linkAuthor == 2">未关联任何播主号</span>
        </div>
        <div slot="Internal" slot-scope="text, record">
          <router-link
              v-if="record.followerCount > 0"
              :to="{
                  name: 'projectDetail',
                  query: { tabActive: 3,id:projectId,radioValue:'1'}
                }"
              target="_blank"
              class="detail-item__value"
          >
            {{ record.followerCount }}
          </router-link>
          <span class="detail-item__value" v-if="record.followerCount == 0">未关联跟进人员</span>
        </div>
        <div slot="activityTime" slot-scope="text, record">
          <div class="detail-item">
            <span class="detail-item__label">添加时间：</span>
            <span class="detail-item__value">{{ record.ctime || "-" }}</span>
          </div>
          <div class="detail-item">
            <span class="detail-item__label">添加人：</span>
            <span class="detail-item__value">{{ record.creatorOauthName || "-" }}</span>
          </div>
        </div>
        <div slot="action" slot-scope="record">
          <router-link
              :to="{
                  name: 'projectDetail',
                  query: { tabActive: 3,id:projectId,kpiBusinessId:getTemporaryParameters.kpiBusinessId, traineeGroupId:record.traineeGroupId, traineeGroupName:record.traineeGroupName, }
                }"
              target="_blank"
              class="detail-item__value"
          >
            查看KPI结果
          </router-link>
          <!-- <router-link
              :to="{
                  name: 'projectDetail',
                  query: { tabActive: 2,id:projectId }
                }"
              target="_blank"
              class="detail-item__value"
              style="padding: 0 10px"
          >
            跟进人员管理
          </router-link> -->
          <a
              href="#"
              style="color: red"
              @click="handelBtn(record,2)"
              v-if="!btnStatus">移除分组</a>
        </div>
      </a-table>
      <base-pagination
          :current-page="pagination.current"
          :page-size="pagination.pageSize"
          :total="pagination.total"
          @onChange="handlePaginationChange"
          @onShowSizeChange="handlePaginationChange"
      />
    </div>


    <createGroupDrawer
        :visible="groupDrawerVisible"
        :title="groupDrawerTitle"
        :project-id="projectId"
        @handleCancel="handleCancel"
    />
  </div>
</template>

<script>
import _API from "@/api/projectManagement";
import createGroupDrawer from "../components/createGroupDrawer";
import { createNamespacedHelpers } from "vuex";


const {mapGetters} = createNamespacedHelpers("temporary_parameters");

export default {
  name: "studentPage",
  components: {createGroupDrawer},
  props: {
    projectId: {
      type: [String, Number],
    },
    follower_name: {
      type: [String, Number],
    },
    project_data: {
      type: Object,
      default: () => {
      }
    },
    btnStatus: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    project_data: {
      handler(val) {
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      searchForm: {
        traineeGroupType: ""
      },
      statusList: [
        // {
        //   id: null,
        //   label: "全部"
        // },
        {
          id: 1,
          label: "短视频"
        },
        {
          id: 2,
          label: "直播"
        },
        {
          id: 3,
          label: "短视频+直播"
        },
      ],
      loading: false,
      columns: [
        {
          title: "公共分组",
          width: 250,
          scopedSlots: {customRender: "groupInfo"}
        },
        {
          title: "学员统计",
          width: 250,
          scopedSlots: {customRender: "statistics"}
        },
        // {
        //   title: "授权情况（主营账号）",
        //   width: 280,
        //   scopedSlots: {customRender: "oauth_status"}
        // },
        // {
        //   title: "内部跟进人员",
        //   width: 250,
        //   scopedSlots: {customRender: "Internal"}
        // },
        {
          title: "添加信息",
          width: 250,
          scopedSlots: {customRender: "activityTime"}
        },
        {
          title: "操作",
          key: "action",
          width: 300,
          scopedSlots: {customRender: "action"}
        }
      ],
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      groupDrawerVisible: false,
      groupDrawerTitle: "",
      kpiBusinessId: this.$route.query.kpiBusinessId
    };
  },
  computed: {
    ...mapGetters(["getTemporaryParameters"])
  },
  mounted() {
    if(this.follower_name) {
      this.$set(this.searchForm, "followerName", this.follower_name);
    }
    this.$nextTick(() => {
      setTimeout(() => {

        this.getTraineeList();

      }, 2000);
    });
  },
  methods: {
    getTraineeList() {
      this.loading = true;
      let params = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        kpiBusinessId: this.getTemporaryParameters.kpiBusinessId || this.project_data.kpiBusinessId || this.kpiBusinessId,
        followerName: this.searchForm.followerName,
        traineeGroupType: this.searchForm.traineeGroupType,
      };
      _API.getTraineeList(params).then((res) => {
        if(res.code == 200) {
          this.list = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.list = [];
          this.pagination.total = 0;
          this.$message.error(res.message || "获取学员列表失败");
        }
        this.loading = false;
      });
    },
    bindSearch() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getTraineeList();
    },
    resetSearch() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.searchForm = {
        traineeGroupType: ""
      };
      this.getTraineeList();
    },
    createGroup() {
      this.groupDrawerVisible = true;
      this.groupDrawerTitle = "添加学员分组到项目";
    },
    handleCancel() {
      this.groupDrawerVisible = false;
      this.getTraineeList();
      this.$emit("updateDetail");
    },
    handlePaginationChange(page, size) {
      this.pagination.current = page;
      this.pagination.pageSize = size;
      this.getTraineeList();
    },
    handelBtn(row, type) {
      let that = this;
      switch(type) {
        case 0:
          this.$emit("handelTabActive", 3);
          // console.log("查看");
          break;
        case 1:
          this.$emit("handelTabActive", 2);
          // console.log("跟进人员管理");
          break;
        case 2:
          that.$confirm({
            title: "确定要移除该分组吗？如误删可重新添加",
            okText: "删除",
            cancelText: "取消",
            onOk() {
              // console.log("删除");
              let params = {
                traineeGroupId: row.traineeGroupId,
                kpiBusinessId: that.project_data.kpiBusinessId,
              };
              that.deleteGroup(params);
            },

          });
          break;
      }
    },
    getItemNum(row, type) {
      switch(type) {
        case 0:
          this.$set(row, "loading", true);
          _API.getAuthoreCount({
            businessId: this.project_data.kpiBusinessId,
            groupId: row.traineeGroupId
          }).then(res => {
            if(res.code == 200) {
              this.$set(row, "authoreCount", res.data.toString());
            }
            this.$set(row, "loading", false);
          });
          break;
        case 1:
          this.$set(row, "authore_loading", true);
          _API.getAuthorizationSituation({
            businessId: this.project_data.kpiBusinessId,
            groupId: row.traineeGroupId
          }).then(res => {
            if(res.code == 200) {
              this.$set(row, "linkAuthor", res.data.linkAuthor ? res.data.linkAuthor.toString() : "");
              this.$set(row, "p1FinishCount", res.data.p1FinishCount.toString());
              this.$set(row, "p1NotYetCount", res.data.p1NotYetCount.toString());
              this.$set(row, "p1ExpiredCount", res.data.p1ExpiredCount.toString());
              this.$set(row, "p2NotYetCount", res.data.p2NotYetCount.toString());
              this.$set(row, "p2FinishCount", res.data.p2FinishCount.toString());
              this.$set(row, "p2ExpiredCount", res.data.p2ExpiredCount.toString());
            }
            this.$set(row, "authore_loading", false);
          });
          break;
      }
    },
    deleteGroup(params) {
      _API.deleteGroup(params).then(res => {
        if(res.code == 200) {
          this.$message.success("操作成功");
          this.getTraineeList();
          this.$emit("updateDetail");
        } else {
          this.$message.error("操作失败");
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.mr-20 {
  margin-right: 20px;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
}

.data-table {
  padding-top: 30px;
}


.detail-item {
  display: flex;
  font-weight: bold;

  &__value {
    flex: 1;
    font-weight: 400;
  }
}

</style>
