<template>
    <div>
        <div style="display: flex; align-items: center;">
            <div v-if="list.length" style="flex: 1;">
                <div style="font-size: 14px">
                    播主分组{{ project_data.authorGroupCount || 0}}，播主号{{ project_data.authorCount || 0 }}
                </div>
            </div>
            <div class="flex-end">
                <a-button
                    class="mr-20"
                    type="primary"
                    icon="plus"
                    @click="createGroup"
                    :disabled="btnStatus"
                >
                    添加分组
                </a-button>
            </div>
        </div>

        <div class="data-table">
            <a-table
                :loading="loading"
                :columns="columns"
                :data-source="list"
                :row-key="(record, index) => index"
                :pagination="false"
                :scroll="{ x: 1000}"
            >
                <div slot="groupInfo" slot-scope="text, record">
                    <div class="detail-item">
                        <span class="detail-item__label">名称：</span>
                        <router-link
                            :to="{
                                name: 'authorGroupDetail',
                                params: { groupId: record.authorAccountGroupId },
                            }"
                            target="_blank"
                            class="detail-item__value"
                        >
                        {{ record.authorAccountGroupName || "-" }}
                        </router-link>
                    </div>
                </div>
                <div slot="statistics" slot-scope="text, record">
                    <div class="detail-item">
                        <span class="detail-item__label">播主号：</span>
                        <span class="detail-item__value">{{ record.authorCount || 0 }}</span>
                    </div>
                    <div class="detail-item">
                        <span class="detail-item__label">蓝V号：</span>
                        <a-button
                            type="link"
                            v-if="!record.authorBusinessCount"
                            :loading="record.loading"
                            @click="getItemNum(record)">获取
                        </a-button>
                        <span class="detail-item__value" v-else>{{ record.authorBusinessCount }}</span>
                    </div>
                    <div class="detail-item">
                        <span class="detail-item__label">主营帐号：</span>
                        <a-button
                            type="link"
                            v-if="!record.authorMainCount"
                            :loading="record.loading"
                            @click="getItemNum(record)">获取
                        </a-button>
                        <span class="detail-item__value" v-else>{{ record.authorMainCount || 0 }}</span>
                    </div>
                </div>
                <div slot="activityTime" slot-scope="text, record">
                    <div class="detail-item">
                        <span class="detail-item__label">添加时间：</span>
                        <span class="detail-item__value">{{ record.ctime || "-" }}</span>
                    </div>
                    <div class="detail-item">
                        <span class="detail-item__label">添加人：</span>
                        <span class="detail-item__value">{{ record.creatorOauthName || "-" }}</span>
                    </div>
                </div>
                <div slot="action" slot-scope="record">
                    <a-button
                        type="link"
                        @click="handelBtn(record)"
                        v-if="!btnStatus"
                        style="color: red"
                    >
                        移除分组
                    </a-button>
                </div>
            </a-table>
            <!-- <base-pagination
                :current-page="pagination.current"
                :page-size="pagination.pageSize"
                :total="pagination.total"
                @onChange="handlePaginationChange"
                @onShowSizeChange="handlePaginationChange"
            /> -->
        </div>

        <createMediaGroupDrawer
            :visible="groupDrawerVisible"
            :title="groupDrawerTitle"
            :project-id="projectId"
            @handleCancel="handleCancel"
        />
    </div>
</template>


<script>
import _API from "@/api/projectManagement";
import createMediaGroupDrawer from "../components/createMediaGroupDrawer";
import { createNamespacedHelpers } from "vuex";


const {mapGetters} = createNamespacedHelpers("temporary_parameters");

export default {
    name: "mediaPage",
    components: {createMediaGroupDrawer},
    props: {
        projectId: {
            type: [String, Number],
        },
        follower_name: {
            type: [String, Number],
        },
        project_data: {
            type: Object,
            default: () => {}
        },
        btnStatus: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        project_data: {
            handler(val) {
                this.$nextTick(() => {
                    this.getAuthorPageList();
                })
            },
            deep: true,
            immediate: true,
        },
    },
    data() {
        return {
            searchForm: {
                traineeGroupType: ""
            },
            statusList: [
                // {
                //   id: null,
                //   label: "全部"
                // },
                {
                    id: 1,
                    label: "短视频"
                },
                {
                    id: 2,
                    label: "直播"
                },
                {
                    id: 3,
                    label: "短视频+直播"
                },
            ],
            loading: false,
            columns: [
                {
                    title: "播主公共分组",
                    width: 250,
                    scopedSlots: {customRender: "groupInfo"}
                },
                {
                    title: "播主统计",
                    width: 250,
                    scopedSlots: {customRender: "statistics"}
                },
                {
                    title: "添加信息",
                    width: 250,
                    scopedSlots: {customRender: "activityTime"}
                },
                {
                    title: "操作",
                    key: "action",
                    width: 300,
                    scopedSlots: {customRender: "action"}
                }
            ],
            list: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
            },
            groupDrawerVisible: false,
            groupDrawerTitle: "",
            authorCount: 0,
            kpiBusinessId: this.$route.query.kpiBusinessId,
        };
    },
    computed: {
        ...mapGetters(["getTemporaryParameters"])
    },
    methods: {
        createGroup() {
            this.groupDrawerVisible = true;
            this.groupDrawerTitle = "添加播主分组到项目";
        },
        handlePaginationChange(page, size) {
            this.pagination.current = page;
            this.pagination.pageSize = size;
            this.getAuthorPageList();
        },
        getAuthorPageList() {
            this.loading = true;
            let params = {
                // page: this.pagination.current,
                // size: this.pagination.pageSize,
                kpiBusinessId: this.getTemporaryParameters.kpiBusinessId || this.kpiBusinessId,
            };
            _API.getAuthorPageList(params).then((res) => {
                if(res.code == 200) {
                    this.list = res.data.list || [];
                    // this.pagination.total = res.data.total || 0;
                    
                    let authorCount = 0
                    this.list.map(item => {
                        authorCount += Number(item.authorCount)
                    })
                    this.authorCount = authorCount
                } else {
                    this.list = [];
                    // this.pagination.total = 0;
                    this.$message.error(res.message || "获取媒体号列表失败");
                }
                this.loading = false;
            });
        },
        getItemNum(row) {
            this.$set(row, "loading", true);
            _API.getAuthorMainCount({
                groupId: row.authorAccountGroupId
            }).then(res => {
                if(res.code == 200) {
                    this.$set(row, "authorBusinessCount", res.data.authorBusinessCount.toString());
                    this.$set(row, "authorMainCount", res.data.authorMainCount.toString());
                }
                this.$set(row, "loading", false);
            });
        },
        handelBtn(row) {
            let that = this
            this.$confirm({
                title: "确定要移除该分组吗？如误删可重新添加",
                okText: "删除",
                cancelText: "取消",
                onOk() {
                    let params = {
                        authorGroupId: row.authorAccountGroupId,
                        kpiBusinessId: that.project_data.kpiBusinessId,
                    };
                    that.delAuthor(params);
                },

            });
        },
        delAuthor(params) {
            _API.delAuthor(params).then(res => {
                if(res.code == 200) {
                this.$message.success("操作成功");
                this.getAuthorPageList();
                this.$emit("updateDetail");
                } else {
                this.$message.error("操作失败");
                }
            });
        },
        handleCancel() {
            this.groupDrawerVisible = false;
            this.getAuthorPageList();
            this.$emit("updateDetail");
        },
    }
}
</script>

<style scoped lang="less">
.mr-20 {
    margin-right: 20px;
}

.flex-end {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0;
}

.data-table {
    padding-top: 30px;
}


.detail-item {
    display: flex;
    align-items: center;
    font-weight: bold;

    &__value {
        flex: 1;
        font-weight: 400;
    }
}
</style>