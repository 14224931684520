<template>
  <a-drawer
      :visible="visible"
      class="custom-class"
      :title="title"
      placement="right"
      width="60%"
      @close="handleCancel"
  >
    <p>
      从 {{ row_data.traineeGroupName || "-" }} 分组中选择指定学员(经销商)
    </p>
    <a-row class="content">
      <a-col :span="10">
        <div class="select-box">
          <a-input
              placeholder="请输入"
              allow-clear
              v-model="traineeName"
          />
          <base-button
              :type="'primary'"
              :title="'刷新学员列表'"
              @onClickBtn="updataGroupList"
              style="margin-left: 10px"
          >
          </base-button>
        </div>
        <div class="checkbox-box">
          <a-spin v-if="loading" size="large" tip="加载中..." style="padding: 24px"/>
          <a-row
              style="padding: 8px 0"
              v-for="(item, index) in groupList"
              :key="index">
            <a-col :span="24">
              <a-checkbox
                  :value="item.dealerId"
                  :checked="(groupIds || []).some(v => v.traineeGroupId == item.dealerId)"
                  @change="onChangeGroup(item)"
              >
                {{ item.dealerName }}
              </a-checkbox>
            </a-col>
          </a-row>
        </div>
        <div class="bottom-link">
          <router-link
              :to="{
                  name: 'dealerList',
                }"
              target="_blank"
          >
            想添加其它经销商？把它加到分组里吧！
          </router-link>
        </div>
      </a-col>
      <a-col :span="1">
        <div class="line"></div>
      </a-col>
      <a-col :span="10">
        <p>已选择的学员（经销商）</p>
        <div v-for="(item,index) of groupIds" :key="index">
          <div class="select-box" style="padding: 8px 0">
            <span class="groupName">{{ item.dealerName }}</span>
            <a-icon type="close-circle" theme="outlined" style="font-size: 22px;padding-left: 10px"
                    @click="removeGroup(index,item.traineeGroupId)"/>
          </div>
        </div>
      </a-col>
    </a-row>
    <template solt="footer">
      <div class="flex-end">
        <base-button
            :title="'取消'"
            @onClickBtn="handleCancel"
            style="margin-right: 20px"
        >
        </base-button>
        <base-button
            :type="'primary'"
            :title="'确定'"
            :loading="is_submit"
            :disabled="is_submit"
            @onClickBtn="handleSubmit"
        >
        </base-button>
      </div>
    </template>
  </a-drawer>
</template>

<script>
import _API from "@/api/projectManagement";

const debounce = (function() {
  let timer = 0;
  return function(callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();


export default {
  name: "designatedTraineeDrawer",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "添加学员分组到项目",
    },
    projectId: {
      type: [String, Number],
    },
    assessmentCycleId: {
      type: [String, Number],
    },
    row_data: {
      type: Object,
      default: () => {
      }
    },
    groupDetail: {
      type: Object,
      default: () => {
      }
    },
  },
  watch: {
    traineeName: {
      handler: function(value) {
        debounce(() => {
          this.getGroupList("search");
        }, 500);
      },
    },
    /*row_data: {
      handler: function(value) {
        if(value.traineeArray && value.traineeArray.length > 0) {
          this.getGroupList();
          this.groupIds = value.traineeArray.map(item => {
            let obj = {
              dealerName: item.traineeName,
              traineeGroupId: item.traineeId,
            };
            return obj;
          });
        }
      },
      immediate: true,
      deep: true
    },*/
  },
  data() {
    return {
      groupList: [],
      awemeGroupIdList: [
        {value: 1, label: "短视频"},
        {value: 2, label: "直播"},
      ],
      groupIds: [],
      is_submit: false,
      loading: false,
      traineeName: "",
    };
  },
  mounted() {
  },
  methods: {
    getGroupList(type) {
      this.loading = true;
      let params = {
        assessmentCycleId: this.assessmentCycleId,
        followerId: this.groupDetail.followerId,
        groupId: this.row_data.traineeGroupId,
        traineeName: this.traineeName,
      };
      _API.getTraineelGroup(params).then((res) => {
        this.loading = false;
        if(res.code == 200) {
          this.groupList = res.data || [];
          if(type && type == "search") return;
          let list = (res.data || []).filter(v => v.isSelected);
          this.groupIds = list.map(item => {
            let obj = {};
            if(item.isSelected) {
              obj = {
                dealerName: item.dealerName,
                traineeGroupId: item.dealerId,
              };
            }
            return obj;
          });
        } else {
          this.$message.error(res.message || "获取学员列表失败");
        }
      });
    },
    updataGroupList() {
      this.traineeName = "";
      this.getGroupList();
    },
    onChangeGroup(value) {
      const {dealerId, dealerName} = value;
      if(this.groupIds.some(v => v.traineeGroupId == dealerId)) {
        this.groupIds = this.groupIds.filter(v => v.traineeGroupId !== dealerId);
        return;
      }

      const obj = {traineeGroupId: dealerId, dealerName, traineeGroupType: undefined};
      this.groupIds.push(obj);
    },
    removeGroup(index) {
      this.groupIds.splice(index, 1);
    },
    handleCancel() {
      this.$emit("handleCancel");
      this.groupIds = [];
      this.groupList = [];
      this.traineeName = "";
    },
    handleSubmit() {
      if(this.groupIds.length) {
        this.$emit("checked", this.groupIds);
        this.groupIds = [];
        this.groupList = [];
        this.traineeName = "";
      } else {
        this.$message.error("请选择指定学员");
      }
    },
    addTrainee(data) {
      let params = {
        groupIds: data,
        projectId: this.projectId
      };
      _API.addTrainee(params).then((res) => {
        if(res.code == 200) {
          this.$message.success("保存成功");
          this.handleCancel();
        } else {
          this.$message.error(res.message || "保存失败");
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.select-box {
  display: flex;
  align-items: center;
}

.line {
  width: 1px;
  background: #c2c2c2;
  height: calc(100vh - 300px);
  margin: 0 8px;
}

.content {
  height: calc(100vh - 300px);
  overflow-y: auto;
}

.groupName {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
  padding: 40px 0 20px 20%;
  border-top: 1px solid #cccccc
}

.checkbox-box {
  padding: 15px;
  height: calc(100vh - 354px);
  overflow-y: auto;
  position: relative;

  .bottom-link {
    position: absolute;
    bottom: 8%;
  }
}
</style>
