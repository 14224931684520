<template>
  <div>
    <a-form-model
        ref="searchForm"
        :model="searchForm"
        layout="inline"
    >
      <a-row>
        <a-col :span="6">
          <a-form-model-item label="所属分组">
            <a-select
                v-model="searchForm.traineeGroupId"
                style="width: 160px;"
            >
              <a-select-option
                  key=""
              >
                全部
              </a-select-option>
              <a-select-option
                  v-for="item in traineeGroupOptionArray"
                  :key="item.traineeGroupId"
                  :value="item.traineeGroupId"
              >
                <a-tooltip>
                  <template slot="title">
                    {{ item.traineeGroupName }}
                  </template>
                  {{ item.traineeGroupName }}
                </a-tooltip>
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="跟进人员">
            <a-input
                v-model.trim="searchForm.followerName"
                allow-clear
                placeholder="请输入"
                style="width: 160px;"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="考核周期">
            <a-select
                v-model="searchForm.assessmentCycleId"
                style="width: 160px;"
            >
              <a-select-option
                  v-for="item in assessmentCycleOptionArray"
                  :key="item.assessmentCycleId"
                  :value="item.assessmentCycleId"
                  v-if="item.isAllCycle != 1"
              >
                {{ item.assessmentCycleName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="关联学员">
            <a-input
                v-model.trim="searchForm.traineeName"
                allow-clear
                placeholder="请输入"
                style="width: 160px;"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col class="flex-end">
          <a-button
              class="mr-20"
              type="primary"
              icon="search"
              @click="bindSearch"
          >
            查询
          </a-button>
          <a-button
              class="mr-20"
              icon="redo"
              @click="resetSearch"
          >
            重置
          </a-button>

          <a-button
              v-if="getAddBaseOrg"
              class="mr-20"
              type="primary"
              icon="plus"
              @click="handleClick({},'2')"
          >
            添加跟进人员
          </a-button>
          <a-button
              class="mr-20"
              type="primary"
              :loading="export_loading"
              @click="handleExport"
          >
            导出
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>

    <div class="data-table">
      <a-table
          :loading="loading"
          :columns="columns"
          :data-source="list"
          :row-key="(record, index) => index"
          :pagination="false"
          :scroll="{ x: 1000}"
      >
        <div slot="groupInfo" slot-scope="text, record">
          <span v-if="record.followerGroupCount.toString() == 0">未关联分组</span>
          <router-link
              v-if="addBaseOrg && record.followerGroupCount.toString() != 0"
              :to="{
                  name: 'projectDetail',
                  query: { tabActive: 1,id:projectId,followerName:record.followerName,}
                }"
              target="_blank"
              class="detail-item__value"
          >
            {{ record.followerGroupCount }}
          </router-link>
          <span v-if="!addBaseOrg && record.followerGroupCount.toString() != 0">{{ record.followerGroupCount }}</span>
        </div>
        <div slot="associateStudent" slot-scope="text, record">
          <span v-if="record.traineeCount.toString() == 0">未关联学员</span>
          <router-link
              v-if="addBaseOrg && record.traineeCount.toString() != 0"
              :to="{
                  name: 'projectDetail',
                  query: { tabActive: 3,id:projectId,radioValue:'1'}
                }"
              target="_blank"
              class="detail-item__value"
          >
            {{ record.traineeCount }}
          </router-link>
          <span v-if="!addBaseOrg && record.traineeCount.toString() != 0">{{ record.traineeCount }}</span>
        </div>
        <div slot="action" slot-scope="text, record">
          <a
              href="#"
              style="color: red"
              @click="handleClick(record,'0')"
              v-if="isT5 && getTemporaryParameters.projectStatus != 3 "
          >删除</a>
          <a
              href="#"
              style="padding: 12px"
              @click="handleClick(record,'1')"
              v-if="isT5 && getTemporaryParameters.projectStatus != 3 "
          >关联分组</a>
          <router-link
              v-if="record.followerGroupCount.toString() != 0 || record.traineeCount.toString() != 0"
              :to="{ name: 'projectDetail', query: { id: project_id,follower_id:record.id,tabActive: 3,radioValue:'0',kpiBusinessId:getTemporaryParameters.kpiBusinessId } }"
              target="_blank"
          >
            查看学员及分组名单
          </router-link>
        </div>
      </a-table>
      <base-pagination
          :current-page="pagination.current"
          :page-size="pagination.pageSize"
          :total="pagination.total"
          @onChange="handlePaginationChange"
          @onShowSizeChange="handlePaginationChange"
      />
    </div>

    <!--添加跟进人员-->
    <BaseOrgModal
        v-model="baseOrgVisible"
        :checked-data="baseOrgCheckedData"
        :multiple="baseOrgMultiple"
        @confirm="handleBaseOrgConfirm"
    />

    <!--关联分组-->
    <associativeGroupingModal
        ref="associativeGroupingModal"
        :visible="associativeGroupingVisible"
        :record_data="record_data"
        :project-id="projectId"
        :assessment-cycle-id="searchForm.assessmentCycleId"
        @confirm="handleassociativeGroupingConfirm"
        @handleCancel="handleassociativeGroupingCancel"
    />

    <!--设置考核周期-->
    <assessmentCycleDrawer
        :visible="assessmentCycleVisible"
        :cycle-list="cycleList"
        :project-id="projectId"
        @handleCancel="handleCancel"
        :project_data="JSON.stringify(getTemporaryParameters)"
    />
  </div>
</template>

<script>
import BaseOrgModal from "@/components/BaseOrgModal";
import associativeGroupingModal from "../components/associativeGroupingModal";
import _API from "@/api/projectManagement";
import { downloadExcel } from "@/utils";
import _https from "@/api/workOrder";
import { cloneDeep, isEqual } from "lodash-es";
import moment from "moment/moment";
import assessmentCycleDrawer from "../components/assessmentCycleDrawer";


import { createNamespacedHelpers } from "vuex";


const {mapGetters} = createNamespacedHelpers("temporary_parameters");
export default {
  name: "followUpPersonnel",
  components: {BaseOrgModal, associativeGroupingModal, assessmentCycleDrawer},
  props: {
    projectId: {
      type: [String, Number],
    },
    assessmentCycleId: {
      type: [String, Number],
    },
    isAssessmentCycleId: {
      type: [String, Number],
      default: 0
    },
    project_data: {
      type: Object,
      default: () => {
      },
    },
  },
  watch: {
    project_data: {
      handler() {
        this.projectData = this.project_data;
      },
      deep: true,
    },
    "searchForm.assessmentCycleId": {
      handler() {
        this.getFollowerList();
        this.$nextTick(() => {
          if(this.isAssessmentCycleId == 1) {
            this.$set(this.searchForm, "assessmentCycleId", this.assessmentCycleId);
          }
        }, 800);
        /* const assessmentCycleOptionArray = this.assessmentCycleOptionArray;
         // 全周期
         const {endDate} = assessmentCycleOptionArray.find(v => isEqual(v.isAllCycle, 1)) || {};
         console.log(endDate, "全周期");
         // 当前选中的周期
         const assessmentCycleTime = assessmentCycleOptionArray.find(v => isEqual(v.assessmentCycleId, val)) || {};
         // 当天日期
         let date = moment(new Date()).startOf("date"); // .format("YYYY-MM-DD");
         // 全周期 +5
         // 全周期日期
         // 全周期+5
         let date5 = endDate && moment(cloneDeep(endDate)).add(5, "days").startOf("date") || null; //.format("YYYY-MM-DD");
         console.log(moment(date5).format("YYYY-MM-DD"), "全周期+5");
         // 选中周期的结束时间
         const end = assessmentCycleTime.endDate && moment(assessmentCycleTime.endDate).startOf("date") || null;
         // 选中周期的开始时间
         const start = assessmentCycleTime.startDate && moment(assessmentCycleTime.startDate).startOf("date") || null;
         // 如考核周期筛选项为历史周期（即周期内所有日期已过去）
         // 获取当前选中是否是历史时间
         const isHistory = assessmentCycleTime.endDate < date;
         // 当前处于全周期T+5内
         const isT5 = date <= date5;  // && isHistory;
         this.isT5 = date < date5;
         // 当前选中是否为当前周期
         const isCurrent = assessmentCycleTime.isSelected;
         // 是否在选择的周期内 或者 是未来周期
         const isCurrentCycle = (isCurrent && start <= date && end >= date) || (isCurrent && start > date);
         // 有一个周期
         this.addBaseOrg = this.getTemporaryParameters.projectStatus != 3 && isT5;
         // this.addBaseOrg = (this.getTemporaryParameters.projectStatus != 3 || !!(this.assessmentCycleOptionArray.length)) || (isT5 || isCurrentCycle); //  && isCurrentCycleDay*/
      },
    },
  },
  computed: {
    ...mapGetters(["getTemporaryParameters"]),
    getAddBaseOrg() {
      const assessmentCycleOptionArray = this.assessmentCycleOptionArray;
      // 全周期
      const {endDate} = assessmentCycleOptionArray.find(v => isEqual(v.isAllCycle, 1)) || {};
      // console.log(endDate, "全周期");
      // 当天日期
      let date = moment(new Date()).startOf("date"); // .format("YYYY-MM-DD");
      // 全周期 +5
      // 全周期日期
      // 全周期+5
      let date5 = endDate && moment(cloneDeep(endDate)).add(6, "days").startOf("date") || null; //.format("YYYY-MM-DD");
      // console.log(moment(date5).format("YYYY-MM-DD"), "全周期+5");
      // 当前处于全周期T+5内
      const isT5 = date < date5;  // && isHistory;
      this.isT5 = date < date5;
      // 有一个周期
      this.addBaseOrg = this.getTemporaryParameters.projectStatus == 2 || (this.getTemporaryParameters.projectStatus != 3 && assessmentCycleOptionArray.length == 0) || (this.getTemporaryParameters.projectStatus == 1 && isT5);
      return this.addBaseOrg;
    }
  },
  data() {
    return {
      searchForm: {
        traineeGroupId: "",
        assessmentCycleId: "",
      },
      assessmentCycleOptionArray: [],
      loading: false,
      columns: [
        {
          title: "跟进人员",
          dataIndex: "followerName",
          width: 180,
        },
        {
          title: "部门",
          dataIndex: "followerDepartment",
          width: 180,
        },
        {
          title: "所属分组",
          width: 100,
          scopedSlots: {customRender: "groupInfo"},
        },
        {
          title: "关联学员",
          width: 160,
          scopedSlots: {customRender: "associateStudent"},
        },
        {
          title: "添加时间",
          width: 220,
          dataIndex: "ctime",
        },
        {
          title: "操作",
          width: 250,
          scopedSlots: {customRender: "action"},
        },
      ],
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      baseOrgVisible: false,
      baseOrgCheckedData: [],
      baseOrgMultiple: false,
      associativeGroupingVisible: false,
      traineeGroupOptionArray: [],
      export_loading: false,
      projectData: {},
      addBaseOrg: false,
      record_data: {},
      assessmentCycle_list: [],
      cycleList: [],
      assessmentCycleVisible: false,
      isT5: false,
      project_id: this.$route.query.id
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.getFollowerOptions();
        this.getFollowerList();
      }, 2000);
    });
  },
  methods: {
    getCycleList() {
      _API.getCycleList({kpiBusinessId: this.getTemporaryParameters.kpiBusinessId}).then((res) => {
        if(res.code == 200) {
          this.assessmentCycle_list = res.data || [];
        } else {
          this.$message.error(res.message || "获取考核周期列表失败");
        }
      });
    },
    getFollowerOptions() {
      _API.getFollowerOptions({businessId: this.getTemporaryParameters.kpiBusinessId}).then(res => {
        if(res.code == 200) {
          this.traineeGroupOptionArray = res.data.traineeGroupOptionArray || [];
          let id = "";
          this.assessmentCycleOptionArray = res.data.assessmentCycleOptionArray.map(item => {
            if(item.isSelected) {
              // item.assessmentCycleName = `${ item.assessmentCycleName }(当前周期)`;
              id = item.assessmentCycleId;
            }
            return item;
          });
          this.$set(this.searchForm, "assessmentCycleId", id || "");
          // this.addBaseOrg = this.getTemporaryParameters.projectStatus != 3 || !!(this.assessmentCycleOptionArray.length);
        } else {
          this.traineeGroupOptionArray = [];
          this.assessmentCycleOptionArray = [];
          this.$message.error(res.message || "获取失败");
        }
      });
    },
    getFollowerList() {
      this.loading = true;
      let params = this.searchForm;
      params.page = this.pagination.current;
      params.size = this.pagination.pageSize;
      params.businessId = this.getTemporaryParameters.kpiBusinessId;
      _API.getFollowerList(params).then(res => {
        if(res.code == 200) {
          this.list = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.list = [];
          this.pagination.total = 0;
          this.$message.error(res.message || "获取失败");
        }
        this.loading = false;
      });
    },
    bindSearch() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getFollowerList();
    },
    resetSearch() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.searchForm = {
        traineeGroupId: "",
      };
      this.getFollowerOptions();
    },
    handlePaginationChange(page, size) {
      // console.log(page, size);
      this.pagination.pageSize = size;
      this.pagination.current = page;
      this.getFollowerList();
    },
    handleExport() {
      this.exportFollowerList();
    },
    async exportFollowerList() {
      this.export_loading = true;
      try {
        let params = this.searchForm;
        params.page = this.pagination.current;
        params.size = this.pagination.pageSize;
        params.businessId = this.getTemporaryParameters.kpiBusinessId;
        const res = await _API.exportFollowerList(params);
        downloadExcel(res, "跟进人员列表");
        this.export_loading = false;
      } catch(e) {
        this.export_loading = false;
        this.$message.error("导出失败");
      }

    },
    handleClick(record, type) {
      let that = this;
      switch(type) {
        case "0":

          that.$confirm({
            title: "提示",
            content: "确要删除该跟进人员吗？如误删可重新添加",
            okText: "删除",
            onOk() {
              // console.log("删除");
              let params = {
                assessmentCycleId: record.assessmentCycleId,
                followerId: record.followerId,
                businessId: that.getTemporaryParameters.kpiBusinessId,
                id: record.id,
              };
              _API.delFollower(params).then(res => {
                if(res.code == 200) {
                  that.getFollowerList();
                  that.$message.success(res.message || "操作成功");
                } else {
                  that.$message.error(res.message || "操作失败");
                }
              });
            },
          });
          break;
        case "1":
          // console.log("关联分组");
          that.record_data = [];
          that.associativeGroupingVisible = true;
          that.record_data = record;
          break;
        case "2":
          // console.log("添加跟进人员");
          // console.log(that.assessmentCycleOptionArray.length);
          if(!that.assessmentCycleOptionArray.length) {
            that.$confirm({
              title: "提示",
              content: "请先设置考核周期再添加跟进人员",
              okText: "设置考核周期",
              cancelText: "取消",
              onOk() {
                that.showAssessmentCycleVisible();
              },
            });
          } else {
            that.baseOrgVisible = true;
          }
          break;
      }
    },
    handleBaseOrgConfirm(val) {
      if(JSON.stringify(val) === "{}") {
        this.$message.error("请选择跟进人员");
        this.baseOrgVisible = true;
      } else {
        const checkedUser = val.checkedUser[0].node;
        let ids = [];
        let dept_name = "";
        ids.push(checkedUser.userid);
        _https.checkUserOauthBatch(ids).then(res => {
          if(res.code == 200) {
            dept_name = res.data.boundBfzAccountList[0].dept_name || "";
            const data = {
              assessmentCycleId: this.searchForm.assessmentCycleId,
              followerDepartment: dept_name,
              followerId: checkedUser.userid,
              followerName: checkedUser.employeeName,
              id: "",
              businessId: this.getTemporaryParameters.kpiBusinessId,
            };
            this.saveFollower(data);
          } else {
            this.$message.error(res.message || "获取部门名称失败");
          }
        });
      }
    },
    saveFollower(data) {
      _API.saveFollower(data).then(res => {
        if(res.code == 200) {
          this.baseOrgVisible = false;
          this.getFollowerList();
          this.$message.success(res.message || "保存成功");
        } else {
          this.$message.error(res.message || "保存失败");
        }
      });
    },
    handleassociativeGroupingConfirm() {
    },
    handleassociativeGroupingCancel() {
      this.associativeGroupingVisible = false;
      this.getFollowerList();
    },
    showAssessmentCycleVisible() {
      if(this.assessmentCycle_list.length) {
        this.assessmentCycle_list.forEach((item) => {
          if(item.isAllCycle == 0) {
            this.cycleList.push(cloneDeep(item));
          }
        });
      } else {
        for(let i = 0; i < 3; i++) {
          let obj = {
            cycleName: `第${ i + 1 }周期`,
            startDate: "",
            endDate: "",
            days: 0,
            businessId: this.getTemporaryParameters.kpiBusinessId,
            isAllCycle: 0,
          };
          this.cycleList.push(obj);
        }
      }
      this.assessmentCycleVisible = true;
    },
    handleCancel() {
      this.cycleList = [];
      this.assessmentCycleVisible = false;
      this.getFollowerOptions();
      this.getCycleList();
    },
  },
};
</script>

<style scoped lang="less">
.mr-20 {
  margin-right: 20px;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
}

.data-table {
  padding-top: 30px;
}
</style>
