<template>
  <a-drawer
      :visible="visible"
      class="custom-class"
      :title="title"
      placement="right"
      width="60%"
      @close="handleCancel"
  >
    <p>
      <router-link
          :to="{
                  name: 'dealerList',
                }"
          target="_blank"
      >请先建立该项目相关的经销商公共分组，
      </router-link>
      再从中选择关联到项目中，并设置类型。
    </p>
    <a-row class="content">
      <a-col :span="10">
        <div class="select-box">
          <a-input
              placeholder="请输入"
              allow-clear
              v-model="dealerGroupName"
          />
          <base-button
              :type="'primary'"
              :title="'刷新分组列表'"
              @onClickBtn="updataGroupList"
              style="margin-left: 10px"
          >
          </base-button>
        </div>
        <div class="checkbox-box">
          <a-spin v-if="loading" size="large" tip="加载中..." style="padding: 24px"/>
          <!--<a-checkbox-group
              v-else
              v-model="group_ids"
              style="width: 100%"
              @change="onChangeGroup"
          >-->
          <a-row
              style="padding: 8px 0"
              v-for="(item, index) in groupList"
              :key="index">
            <a-col :span="24">
              <a-checkbox
                  :value="item.dealerId"
                  :checked="(groupIds || []).some(v => v.traineeGroupId == item.dealerId)"
                  @change="onChangeGroup(item)"
              >
                {{ item.dealerName }}
              </a-checkbox>
            </a-col>
          </a-row>
          <!--</a-checkbox-group>-->
        </div>
      </a-col>
      <a-col :span="1">
        <div class="line"></div>
      </a-col>
      <a-col :span="10">
        <p>已选择或添加的经销商公共分组</p>
        <div v-for="(item,index) of groupIds" :key="index">
          <div class="select-box" style="padding: 8px 0">
            <span class="groupName">{{ item.dealerName }}</span>
            <a-select
                v-model="item.traineeGroupType"
                style="width: 200px;padding-left: 10px"
            >
              <a-select-option
                  v-for="item in awemeGroupIdList"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
            <a-icon type="close-circle" theme="outlined" style="font-size: 22px;padding-left: 10px"
                    @click="removeGroup(index,item.traineeGroupId)"/>
          </div>
        </div>
      </a-col>
    </a-row>
    <template solt="footer">
      <div class="flex-end">
        <base-button
            :title="'取消'"
            @onClickBtn="handleCancel"
            style="margin-right: 20px"
        >
        </base-button>
        <base-button
            :type="'primary'"
            :title="'保存'"
            :loading="is_submit"
            :disabled="is_submit"
            @onClickBtn="handleSubmit"
        >
        </base-button>
      </div>
    </template>
  </a-drawer>
</template>

<script>
import _API from "@/api/projectManagement";

const debounce = (function() {
  let timer = 0;
  return function(callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

import { createNamespacedHelpers } from "vuex";

const {mapGetters} = createNamespacedHelpers("temporary_parameters");
export default {
  name: "createGroupDrawer",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "添加学员分组到项目",
    },
    projectId: {
      type: [String, Number],
    },
  },
  watch: {
    dealerGroupName: {
      handler: function(value) {
        debounce(() => {
          this.getGroupList();
        }, 500);
      },
    },
  },
  data() {
    return {
      group_ids: [],
      groupList: [],
      awemeGroupIdList: [
        {value: 1, label: "短视频"},
        {value: 2, label: "直播"},
        {value: 3, label: "短视频+直播"},
      ],
      groupIds: [],
      is_submit: false,
      loading: false,
      dealerGroupName: "",
    };
  },
  mounted() {
    // this.getGroupList();
  },
  computed: {
    ...mapGetters(["getTemporaryParameters"]),
  },
  methods: {
    getGroupList() {
      this.loading = true;
      _API.getGroupList({dealerGroupName: this.dealerGroupName || ""}).then((res) => {
        this.loading = false;
        if(res.code == 200) {
          this.groupList = res.data || [];
        } else {
          this.$message.error(res.message || "获取经销商分组失败");
        }
      });
    },
    updataGroupList() {
      this.dealerGroupName = "";
      this.getGroupList();
    },
    onChangeGroup(value) {
      const {dealerId, dealerName} = value;
      if(this.groupIds.some(v => v.traineeGroupId == dealerId)) {
        this.groupIds = this.groupIds.filter(v => v.traineeGroupId !== dealerId);
        return;
      }

      const obj = {traineeGroupId: dealerId, dealerName, traineeGroupType: undefined};
      this.groupIds.push(obj);
    },
    removeGroup(index) {
      this.groupIds.splice(index, 1);
    },
    handleCancel() {
      this.$emit("handleCancel");
      this.group_ids = [];
      this.groupIds = [];
      this.groupList = [];
      this.dealerGroupName = "";
    },
    handleSubmit() {
      if(this.groupIds.length) {
        const every = this.groupIds.every(v => v.traineeGroupType);
        if(every) {
          this.addTrainee(this.groupIds);
        } else {
          this.$message.error("请选择分组类型");
        }
      } else {
        this.$message.error("请勾选经销商分组");
      }
    },
    addTrainee(data) {
      this.is_submit = true;
      let params = {
        groups: data,
        kpiBusinessId: this.getTemporaryParameters.kpiBusinessId
      };
      _API.addTrainee(params).then((res) => {
        if(res.code == 200) {
          this.$message.success("保存成功");
          this.handleCancel();
        } else {
          this.$message.error(res.message || "保存失败");
        }
        this.is_submit = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
.select-box {
  display: flex;
  align-items: center;
}

.line {
  width: 1px;
  background: #c2c2c2;
  height: calc(100vh - 300px);
  margin: 0 8px;
}

.content {
  height: calc(100vh - 300px);
  overflow-y: auto;
}

.groupName {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
  padding: 40px 0 20px 20%;
  border-top: 1px solid #cccccc
}

.checkbox-box {
  padding: 15px;
  height: calc(100vh - 300px);
  overflow-y: auto;
}
</style>
