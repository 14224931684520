var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form-model',{ref:"searchForm",attrs:{"model":_vm.searchForm,"layout":"inline"}},[_c('a-row',[_c('a-col',{attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"label":"分组类型"}},[_c('a-select',{staticStyle:{"width":"160px"},model:{value:(_vm.searchForm.traineeGroupType),callback:function ($$v) {_vm.$set(_vm.searchForm, "traineeGroupType", $$v)},expression:"searchForm.traineeGroupType"}},[_c('a-select-option',{key:""},[_vm._v(" 全部 ")]),_vm._l((_vm.statusList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.label)+" ")])})],2)],1)],1),_c('a-col',{staticClass:"flex-end"},[_c('a-button',{staticClass:"mr-20",attrs:{"type":"primary","icon":"search"},on:{"click":_vm.bindSearch}},[_vm._v(" 查询 ")]),_c('a-button',{staticClass:"mr-20",attrs:{"icon":"redo"},on:{"click":_vm.resetSearch}},[_vm._v(" 重置 ")]),_c('a-button',{staticClass:"mr-20",attrs:{"type":"primary","icon":"plus","disabled":_vm.btnStatus},on:{"click":_vm.createGroup}},[_vm._v(" 添加分组 ")])],1)],1),(_vm.list.length)?_c('a-row',[_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v(" 学员(经销商)"+_vm._s(_vm.project_data.traineeCount || 0)+" ")])]):_vm._e()],1),_c('div',{staticClass:"data-table"},[_c('a-table',{attrs:{"loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.list,"row-key":function (record, index) { return index; },"pagination":false,"scroll":{ x: 1000}},scopedSlots:_vm._u([{key:"groupInfo",fn:function(text, record){return _c('div',{},[_c('div',{staticClass:"detail-item"},[_c('span',{staticClass:"detail-item__label"},[_vm._v("名称：")]),_c('router-link',{staticClass:"detail-item__value",attrs:{"to":{
                name: 'dealerGroupDetail',
                params: { groupId: record.traineeGroupId }
              },"target":"_blank"}},[_vm._v(" "+_vm._s(record.traineeGroupName || "-")+" ")])],1),_c('div',{staticClass:"detail-item"},[_c('span',{staticClass:"detail-item__label"},[_vm._v("类型：")]),_c('span',{staticClass:"detail-item__value"},[_vm._v(_vm._s(record.traineeGroupType == 1 ? "短视频" : record.traineeGroupType == 2 ? "直播" : record.traineeGroupType == 3 ? '短视频+直播' : '-'))])])])}},{key:"statistics",fn:function(text, record){return _c('div',{},[_c('div',{staticClass:"detail-item"},[_c('span',{staticClass:"detail-item__label"},[_vm._v("学员（经销商）：")]),_c('span',{staticClass:"detail-item__value"},[_vm._v(_vm._s(record.traineeCount || 0))])])])}},{key:"oauth_status",fn:function(text, record){return _c('div',{},[_c('div',{staticClass:"detail-item"},[(!record.p1FinishCount)?_c('a-button',{attrs:{"type":"link","loading":record.authore_loading},on:{"click":function($event){return _vm.getItemNum(record,1)}}},[_vm._v("获取 ")]):_vm._e(),(record.linkAuthor == 1)?_c('div',[_c('div',[_c('span',{staticClass:"detail-item__label"},[_vm._v("P1：")]),_c('router-link',{staticClass:"detail-item__value",attrs:{"to":{
                name: 'authorList',
                query: { authorGroupId: record.traineeGroupId, authorGroupName: record.traineeGroupName, groupCate: 1,isGroupCate:'2' }
              },"target":"_blank"}},[_vm._v(" 已授权"+_vm._s(record.p1FinishCount)+"， 未授权"+_vm._s(record.p1NotYetCount)+"， 授权过期"+_vm._s(record.p1ExpiredCount)+" ")])],1),_c('div',[_c('span',{staticClass:"detail-item__label"},[_vm._v("P2：")]),_c('router-link',{staticClass:"detail-item__value",attrs:{"to":{
                name: 'authorList',
                query: { authorGroupId: record.traineeGroupId, authorGroupName: record.traineeGroupName,groupCate: 1,isGroupCate:'2' }
              },"target":"_blank"}},[_vm._v(" 已授权"+_vm._s(record.p2FinishCount)+"， 未授权"+_vm._s(record.p2NotYetCount)+"， 授权过期"+_vm._s(record.p2ExpiredCount)+" ")])],1)]):_vm._e()],1),(record.linkAuthor == 2)?_c('span',{staticClass:"detail-item__value"},[_vm._v("未关联任何播主号")]):_vm._e()])}},{key:"Internal",fn:function(text, record){return _c('div',{},[(record.followerCount > 0)?_c('router-link',{staticClass:"detail-item__value",attrs:{"to":{
                name: 'projectDetail',
                query: { tabActive: 3,id:_vm.projectId,radioValue:'1'}
              },"target":"_blank"}},[_vm._v(" "+_vm._s(record.followerCount)+" ")]):_vm._e(),(record.followerCount == 0)?_c('span',{staticClass:"detail-item__value"},[_vm._v("未关联跟进人员")]):_vm._e()],1)}},{key:"activityTime",fn:function(text, record){return _c('div',{},[_c('div',{staticClass:"detail-item"},[_c('span',{staticClass:"detail-item__label"},[_vm._v("添加时间：")]),_c('span',{staticClass:"detail-item__value"},[_vm._v(_vm._s(record.ctime || "-"))])]),_c('div',{staticClass:"detail-item"},[_c('span',{staticClass:"detail-item__label"},[_vm._v("添加人：")]),_c('span',{staticClass:"detail-item__value"},[_vm._v(_vm._s(record.creatorOauthName || "-"))])])])}},{key:"action",fn:function(record){return _c('div',{},[_c('router-link',{staticClass:"detail-item__value",attrs:{"to":{
                name: 'projectDetail',
                query: { tabActive: 3,id:_vm.projectId,kpiBusinessId:_vm.getTemporaryParameters.kpiBusinessId, traineeGroupId:record.traineeGroupId, traineeGroupName:record.traineeGroupName, }
              },"target":"_blank"}},[_vm._v(" 查看KPI结果 ")]),(!_vm.btnStatus)?_c('a',{staticStyle:{"color":"red"},attrs:{"href":"#"},on:{"click":function($event){return _vm.handelBtn(record,2)}}},[_vm._v("移除分组")]):_vm._e()],1)}}])}),_c('base-pagination',{attrs:{"current-page":_vm.pagination.current,"page-size":_vm.pagination.pageSize,"total":_vm.pagination.total},on:{"onChange":_vm.handlePaginationChange,"onShowSizeChange":_vm.handlePaginationChange}})],1),_c('createGroupDrawer',{attrs:{"visible":_vm.groupDrawerVisible,"title":_vm.groupDrawerTitle,"project-id":_vm.projectId},on:{"handleCancel":_vm.handleCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }