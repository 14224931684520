<template>
  <a-modal
    :visible="value"
    title="选择部门/员工"
    :width="720"
    :mask-closable="false"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <OrgPick
      v-if="value"
      v-model="checkedData"
      :multiple="multiple"
      @change="handleOrgChange"
    />
  </a-modal>
</template>

<script>
import OrgPick from '@/components/OrgPick'
import deepClone from '@/utils/deepClone'

export default {
  name: 'BaseOrgModal',
  components: {
    OrgPick
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    checkedData: {
      type: Array,
      default() {
        return []
      }
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      data: {}
    }
  },
  methods: {
    handleOrgChange(val) {
      this.data = val
    },
    handleCancel() {
      this.$emit('input', false)
      this.$emit('cancel')
    },
    handleOk() {
      this.$emit('input', false)
      this.$emit('confirm', deepClone(this.data))
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
