<template>
  <div>
    <detail_header ref="detail_header" :id="id" />
    <div style="margin-top: 35px; padding: 20px">
      <a-tabs v-model="activeTab" @change="handleTabsChange">
        <a-tab-pane key="1" tab="学员"></a-tab-pane>
        <!-- <a-tab-pane key="2" tab="跟进人员"></a-tab-pane> -->
        <a-tab-pane key="2" tab="媒体号"></a-tab-pane>
        <a-tab-pane key="3" tab="KPI"></a-tab-pane>
      </a-tabs>

      <!--tabs-->
      <studentPage
        v-if="activeTab == '1'"
        :project-id="id"
        :project_data="getTemporaryParameters"
        :follower_name="followerName"
        :btn-status="btnStatus"
        @updateDetail="updateDetail"
      />
      <!-- <followUpPersonnel
          v-if="activeTab =='2'"
          :project-id="id"
          :assessment-cycle-id="assessmentCycleId"
          :is-assessment-cycle-id="isAssessmentCycleId"
      /> -->
      <mediaPage
        v-if="activeTab == '2'"
        :project-id="id"
        :project_data="getTemporaryParameters"
        :is-assessment-cycle-id="isAssessmentCycleId"
        :btn-status="btnStatus"
      />
      <KPI v-if="activeTab == '3'" :id="id" :kpi-business-id="kpiBusinessId" />
      <!--  <KPIPage
            v-if="activeTab =='3'"
            :id="id"
            :kpi-business-id="kpiBusinessId"
            :radio-value.sync="radioValue"/>-->
    </div>
  </div>
</template>

<script>
import studentPage from "./pages/studentPage";
import followUpPersonnel from "./pages/followUpPersonnel";
import KPI from "./pages/KPI";
import detail_header from "./components/detail_header";
import { createNamespacedHelpers } from "vuex";
import mediaPage from "./pages/mediaPage";
import _API from "@/api/projectManagement";

const { mapGetters } = createNamespacedHelpers("temporary_parameters");
export default {
  name: "projectDetail",
  components: {studentPage, followUpPersonnel, KPI, detail_header, mediaPage},
  data() {
    return {
      activeTab: "1",
      id: this.$route.query.id,
      tabActive: this.$route.query.tabActive,
      radioValue: this.$route.query.radioValue,
      followerName: this.$route.query.followerName,
      isAssessmentCycleId: this.$route.query.isAssessmentCycleId,
      assessmentCycleId: this.$route.query.assessmentCycleId,
      kpiBusinessId: this.$route.query.kpiBusinessId,
      principalList: [{ label: "全部", value: "" }],
      projectModal_visible: false,
      btnStatus: false,
    };
  },
  mounted() {
    if (this.tabActive) {
      this.activeTab = this.tabActive;
    }
    this.judgeProjectAddGroup();
  },
  methods: {
    handleTabsChange() {},
    updateDetail() {
      this.$refs.detail_header.getProjectDetail();
      // this.getProjectDetail();
    },
    judgeProjectAddGroup() {
      _API.judgeProjectAddGroup({ id: this.id }).then((res) => {
        if (res.code == 200) {
          let status = res.data.status; //按钮状态，1隐藏 2置灰 3高亮
          this.btnStatus = status == 3 ? false : true;
        } else {
          this.$message.error(`获取按钮控制状态失败,${res.message}`);
        }
      });
    },
  },
  computed: {
    ...mapGetters(["getTemporaryParameters"]),
  },
};
</script>

<style scoped lang="less"></style>
