<template>
  <div>
    <a-drawer
        :visible="visible"
        :title="title"
        @close="handleCancel"
        :maskClosable="false"
        width="60%"
        :footer="null"
    >
      <a-form-model
          ref="cycle_form"
          :model="cycle_form"
          :rules="cycle_rules"
      >
        <p>
          可将 {{ record_data.followerName || "xx" }}
          关联到指定分组或分组内具体学员。已关联{{ record_data.followerGroupCount || 0 }}个分组，
          {{ record_data.traineeCount || 0 }}个学员。
        </p>
        <div class="content">
          <div v-for="(item,index) of cycle_form.list" :key="index" class="list_box">
            <a-row>
              <a-col :span="6">
                <a-form-model-item
                    label=""
                >
                  <!--@change="item.checked = !item.checked"-->
                  <a-checkbox
                      :checked="item.checked"
                      @change="checkedItem(item)"
                  >
                    {{ item.traineeGroupName || "-" }}
                  </a-checkbox>
                </a-form-model-item>
              </a-col>
              <template v-if="item.checked">
                <a-col :span="8">
                  <a-form-model-item
                      label=""
                      :prop="`list.${index}.relevanceType`"
                      :rules="[
                      {required: true, message: '请选择', trigger: 'blur'}
                  ]"
                  >
                    <a-radio-group v-model="item.relevanceType" @change="clearTraineeCount(item)">
                      <a-radio value="1">整个分组</a-radio>
                      <a-radio value="2">指定分组内学员(经销商)</a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                </a-col>
                <a-col :span="4">
                  <a-form-model-item
                      label=""
                      :prop="`list.${index}.projectRole`"
                      :rules="[
                    {required: true, message: '请选择', trigger: 'blur'}
                  ]"
                  >
                    <a-select
                        v-model="item.projectRole"
                        style="width: 160px"
                    >
                      <a-select-option
                          v-for="item in projectRole_arr"
                          :key="item.id"
                          :value="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </template>
            </a-row>
            <a-row v-if="item.checked && item.relevanceType == '2'">
              <a-col :span="6"></a-col>
              <a-col :span="8">
                <span style="padding-right: 10px">已指定学员：{{ item.traineeCount || 0 }} </span>
                <base-button
                    :type="'primary'"
                    :title="'编辑'"
                    @onClickBtn="showBaseOrgVisible(item)"
                >
                </base-button>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-form-model>
      <template solt="footer">
        <div class="flex-end">
          <base-button
              :title="'取消'"
              @onClickBtn="handleCancel"
              style="margin-right: 20px"
          >
          </base-button>
          <base-button
              :type="'primary'"
              :title="'确定'"
              :loading="is_submit"
              :disabled="is_submit"
              @onClickBtn="handleSubmit"
          >
          </base-button>
        </div>
      </template>
    </a-drawer>
    <!--指定学员-->
    <designatedTraineeDrawer
        :visible="groupDrawerVisible"
        :title="'关联人员到指定学员'"
        :project-id="projectId"
        :assessment-cycle-id="assessmentCycleId"
        :row_data="row_data"
        :groupDetail="groupDetail"
        @handleCancel="handleCancelDesignatedTraineeDrawer"
        @checked="handleChecked"
    />
  </div>
</template>

<script>
import designatedTraineeDrawer from "../components/designatedTraineeDrawer";
import _API from "@/api/projectManagement";
import { cloneDeep, flattenDeep, isEmpty, isEqual, max, min, omit } from "lodash-es";
import moment from "moment/moment";


import { createNamespacedHelpers } from "vuex";


const {mapGetters} = createNamespacedHelpers("temporary_parameters");
export default {
  name: "associativeGroupingModal",
  components: {designatedTraineeDrawer},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "关联人员到分组",
    },
    record_data: {
      type: Object,
      default: () => ({}),
    },
    projectId: {
      type: [String, Number],
    },
    assessmentCycleId: {
      type: [String, Number],
    },
  },
  watch: {
    record_data: {
      handler: function(val) {
        // console.log(val);
        if(val) {
          this.groupDetail = {};
          this.cycle_form.list = [];
          this.getFollowerGroupDetail();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["getTemporaryParameters"])
  },
  data() {
    return {
      cycle_form: {
        list: [],
      },
      cycle_rules: {},
      projectRole_arr: [
        {
          id: 1,
          name: "助教",
        },
        {
          id: 2,
          name: "客服",
        },
        {
          id: 3,
          name: "客户经理",
        },
        {
          id: 4,
          name: "运营经理",
        },
      ],
      groupDrawerVisible: false,
      baseOrgCheckedData: [],
      baseOrgMultiple: true,
      is_submit: false,
      row_data: {},
      groupDetail: {},
    };
  },
  mounted() {
  },
  methods: {
    getFollowerGroupDetail() {
      let params = {
        assessmentCycleId: this.record_data.assessmentCycleId,
        followerId: this.record_data.followerId,
        id: this.record_data.id,
        businessId: this.getTemporaryParameters.kpiBusinessId,
      };
      _API.getFollowerGroupDetail(params).then(res => {
        if(res.code == 200) {
          this.groupDetail = res.data || {};
          const {mappedTraineeGroupArray = [], projectMappingTraineeGroupArray = []} = res.data || {};
          this.cycle_form.list = (projectMappingTraineeGroupArray || []).map(item => {
            const find = mappedTraineeGroupArray.find(v => isEqual(item.traineeGroupId, v.traineeGroupId)) || {};
            return {
              ...item,
              checked: !isEmpty(find),
              relevanceType: `${ find?.relevanceType ?? "1" }`,
              traineeCount: find?.traineeCount ?? 0,
              // dealerCount: find?.dealerCount,
              projectRole: find?.projectRole,
              traineeArray: find?.traineeArray || [],
              assessmentCycleId: this.assessmentCycleId,
              followerId: this.groupDetail.followerId,
              businessId: this.getTemporaryParameters.kpiBusinessId,
            };
          });
        } else {
          this.$message.error(res.message || "获取分组数据失败");
        }
      });
    },
    handleCancel() {
      this.cycle_form.list = [];
      this.$emit("handleCancel");
    },
    handleBaseOrgConfirm(val) {
      // console.log(val);
    },
    handleSubmit() {
      this.$refs.cycle_form.validate((valid, obj) => {
        if(valid) {
          const list = this.cycle_form.list.filter(v => v.checked).map(v => omit(v, ["checked", "ctime", "businessType", "creator", "isDeleted", "traineeGroupType"]));
          if(!list.length) {
            this.$message.error("请选择要关联的分组");
            return;
          }
          this.saveFollowerGroupMapping(list);
        }
      });
    },
    showBaseOrgVisible(item) {
      this.groupDrawerVisible = true;
      this.row_data = item;
    },
    handleCancelDesignatedTraineeDrawer() {
      this.groupDrawerVisible = false;
      this.row_data = {};
    },
    handleChecked(list) {
      // this.$set(this.row_data, 'traineeArray', list?.map(v => ({ traineeId: v.traineeGroupId, traineeName: v.dealerName })));
      // console.log(this.list, this.row_data);
      this.cycle_form.list = this.cycle_form.list.map(item => {
        if(isEqual(item.id, this.row_data.id)) {
          item.traineeArray = list?.map(v => ({traineeId: v.traineeGroupId, traineeName: v.dealerName}));
          item.traineeCount = list.length;
        }
        return item;
      });
      this.groupDrawerVisible = false;
    },
    saveFollowerGroupMapping(list) {
      this.is_submit = true;
      // console.log(list);
      _API.saveFollowerGroupMapping(list).then((res) => {
        if(res.code == 200) {
          this.handleCancel();
          this.$message.success(res.message || "保存成功");
        } else {
          this.$message.error(res.message || "保存失败");
        }
        this.is_submit = false;
      });
    },
    clearTraineeCount(item) {
      // console.log(item);
      this.$set(item, "traineeArray", []);
      this.$set(item, "traineeCount", 0);
    },
    checkedItem(item) {
      if(item.dealerCount == 0) {
        this.$message.error("暂无经销商可以关联，请先添加经销商");
        return;
      }
      this.$set(item, "checked", !item.checked);
    }
  },
};
</script>

<style scoped lang="less">
.list_box {
  padding: 15px 0;
  border-bottom: 1px solid #dde0e5;
}

.content {
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
  padding: 40px 0 20px 20%;
  border-top: 1px solid #cccccc
}
</style>
